/*---------- common ----------*/

/* pagetop */
$(function () {
	/* scroll */
	// #で始まるアンカーをクリックした場合に処理
	$('a[href^="#"]').click(function () {
		// スクロールの速度
		var speed = 500; // ミリ秒
		// アンカーの値取得
		var href = $(this).attr("href");
		// 移動先を取得
		var target = $(href == "#" || href == "" ? 'html' : href);
		// 移動先を数値で取得
		var position = target.offset().top;
		// スムーススクロール
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		if ($('#js_menu').hasClass('is_open')) {
			$('#js_menu').toggleClass('is_open');
			$('.gnav').stop().slideToggle();
		}
		return false;
	});
	$('#js_menu').click(function () {
		$(this).toggleClass('is_open');
		$('.gnav').stop().slideToggle();
		return false;
	})
	skrollr.init();
})
$(window).on('load', function () {
	AOS.init({
		offset: 200,
		duration: 800,
		once: true,
	});
});
